import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faBook, faGraduationCap, faClipboard } from '@fortawesome/free-solid-svg-icons';
import RequireLoginPage from '../auth/RequireLoginPage';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';
import { ThreeDots } from 'react-loading-icons';

const LearnTopicPage = (props) => {
  const [answers, setAnswers] = useState({});
  const [questionsWithExplanations, setQuestionsWithExplanations] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [loadingBasics, setLoadingBasics] = useState(true);
  const [loadingAdvanced, setLoadingAdvanced] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [lessonBasics, setLessonBasics] = useState('');
  const [lessonAdvanced, setLessonAdvanced] = useState('');
  const [lessonUsage, setLessonUsage] = useState('');

  const { topic } = useParams();

  const fetchQuestions = async () => {
    try {
      const encodedTopic = encodeURIComponent(topic);
      const response = await axios.get(`https://zkkldo18wa.execute-api.us-east-1.amazonaws.com/dev/getcontent/${encodedTopic}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch questions');
      }

      const content = response.data.data.questions;
      const questions = content;

      const initialAnswers = questions.reduce((acc, question) => {
        acc[question.id] = '';
        return acc;
      }, {});

      setQuestionsWithExplanations(questions);
      setAnswers(initialAnswers);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoadingQuestions(false);
    }
  };

  const extractHtmlContent = (content) => {
    const regex = /```html(.*?)```/s;
    const match = content.match(regex);
    return match ? match[1] : content;
  };

  const removeStyleTagsContent = (content) => {
    return content.replace(/<style[\s\S]*?<\/style>/gi, '');
  };

  const fetchLessonBasics = async () => {
    try {
      const encodedTopic = encodeURIComponent(topic);
      const response = await axios.get(`https://zkkldo18wa.execute-api.us-east-1.amazonaws.com/dev/getlesson/${encodedTopic}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch lesson basics');
      }
      let content = response.data.data;
      content = removeStyleTagsContent(extractHtmlContent(content));
      setLessonBasics(content);
    } catch (error) {
      console.error('Error fetching lesson basics:', error);
      setLessonBasics('Content currently not available.');
    } finally {
      setLoadingBasics(false);
    }
  };

  const fetchLessonAdvanced = async () => {
    try {
      const encodedTopic = encodeURIComponent(topic);
      const response = await axios.get(`https://zkkldo18wa.execute-api.us-east-1.amazonaws.com/dev/getlessonadvanced/${encodedTopic}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch lesson advanced');
      }
      let content = response.data.data;
      content = removeStyleTagsContent(extractHtmlContent(content));
      setLessonAdvanced(content);
    } catch (error) {
      console.error('Error fetching lesson advanced:', error);
      setLessonAdvanced('Content currently not available.');
    } finally {
      setLoadingAdvanced(false);
    }
  };

  const fetchLessonUsage = async () => {
    try {
      const encodedTopic = encodeURIComponent(topic);
      const response = await axios.get(`https://zkkldo18wa.execute-api.us-east-1.amazonaws.com/dev/getlessonusage/${encodedTopic}`);
      if (response.status !== 200) {
        throw new Error('Failed to fetch lesson usage');
      }
      let content = response.data.data;
      content = removeStyleTagsContent(extractHtmlContent(content));
      setLessonUsage(content);
    } catch (error) {
      console.error('Error fetching lesson usage:', error);
      setLessonUsage('Content currently not available.');
    } finally {
      setLoadingUsage(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
    fetchLessonBasics();
    fetchLessonAdvanced();
    fetchLessonUsage();
  }, [topic]);

  const handleAnswerChange = (e, questionId) => {
    const { value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSuggestedVerbClick = (questionId) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const handleTryAgain = () => {
    setSubmitted(false);
    setLoadingQuestions(true);
    fetchQuestions();
  };

  const handleGenerateAnotherAssessment = () => {
    setSubmitted(false);
    setLoadingQuestions(true);
    fetchQuestions();
  };

  const renderAnswerIndicator = (question) => {
    const userAnswer = answers[question.id] || '';
    const isCorrect = userAnswer.toLowerCase() === question.correctAnswer.toLowerCase();

    if (submitted) {
      return (
        <div style={{ marginTop: '10px' }}>
          {isCorrect ? (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon icon={faCheck} /> {question.sentence.replace(/__+/g, question.correctAnswer)}
              <br />
              <hr />
            </div>
          ) : (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon icon={faTimes} /> {question.sentence.replace(/__+/g, userAnswer)}
              <br />
              <div style={{ color: 'black' }}>
                <small>
                  <strong>{`Correct Answer ${question.number}: "${question.sentence.replace(/__+/g, question.correctAnswer)}"`}</strong>
                  <br />
                  {question.detailExplanation}
                  <br />
                  {`Grammar Category: ${question.grammarCategoryType}`}
                  <hr />
                </small>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderQuestionWithInput = (question) => {
    const parts = question.sentence.split(/(__+)/);
    return (
      <p><strong>Question {question.number}:  </strong>
        {parts.map((part, index) => {
          if (part.match(/__+/)) {
            return (
              <input
                key={index}
                type="text"
                value={answers[question.id] || ''}
                onChange={(e) => handleAnswerChange(e, question.id)}
                placeholder={question.suggestedVerb}
                onClick={() => handleSuggestedVerbClick(question.id)}
                style={{ width: `${part.length * 20}px`, textAlign: 'center', border: 'none', borderBottom: '1px solid black' }}
                required
              />
            );
          }
          return part;
        })}
      </p>
    );
  };

  return (
    <Container fluid className="px-4">
      <h1 className="mb-4">Learn about {topic}</h1>
      <Row>
        <RequireLoginPage isAuthenticated={props.isAuthenticated} />
      </Row>
      {props.isAuthenticated && (
        <Row>
          <Col xs={12} md={6}>
            {loadingQuestions ? (
              <div className="spinner-container">
                <ThreeDots stroke="#7983ff" />
              </div>
            ) : (
              <>
                <h2>Take an Assessment</h2>
                <form onSubmit={handleSubmit}>
                  {questionsWithExplanations.map((question) => (
                    <div key={question.id} className="question" style={{ marginBottom: '20px' }}>
                      {renderQuestionWithInput(question)}
                      {renderAnswerIndicator(question)}
                    </div>
                  ))}

                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    {!submitted && (
                      <>
                        <Button type="submit">Submit Answers</Button>
                        <Button onClick={handleGenerateAnotherAssessment} style={{ marginLeft: '10px' }}>Generate another assessment</Button>
                      </>
                    )}
                    {submitted && (
                      <Button onClick={handleTryAgain}>Take another assessment</Button>
                    )}
                  </div>
                </form>
              </>
            )}
          </Col>
          <Col xs={12} md={6}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <FontAwesomeIcon icon={faBook} className="me-2" /> Basics about {topic}
                </Accordion.Header>
                <Accordion.Body>
                  {loadingBasics ? (
                    <div className="spinner-container">
                      <ThreeDots stroke="#7983ff" />
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: lessonBasics }} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <FontAwesomeIcon icon={faGraduationCap} className="me-2" /> Advanced concepts about {topic}
                </Accordion.Header>
                <Accordion.Body>
                  {loadingAdvanced ? (
                    <div className="spinner-container">
                      <ThreeDots stroke="#7983ff" />
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: lessonAdvanced }} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <FontAwesomeIcon icon={faClipboard} className="me-2" /> Usage in context for {topic}
                </Accordion.Header>
                <Accordion.Body>
                  {loadingUsage ? (
                    <div className="spinner-container">
                      <ThreeDots stroke="#7983ff" />
                    </div>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: lessonUsage }} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default LearnTopicPage;
