import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

function RequireLoginPage(props) {

    const isLoggedIn = props.isAuthenticated;
    return (
      <div>
        {isLoggedIn === false ? (
                <>
                <p className="mt-1">
                Please login. You must be logged in to proceed.
                <br /><br />
                </p>
                <Link to='/login'><Button variant="outline-primary">Login &gt;&gt;</Button></Link>
                 &nbsp;&nbsp;
                <Link to='/register'><Button variant="outline-primary">Register &gt;&gt;</Button></Link>
                </>
        ) : (<></>)
        }
      </div>
    );

    /*
    return (
      <div>
        {props.isAuthenticated === false ? 
            (
                <>
                    <p className="mt-1">
                    Please login. You must be logged in to proceed.
                    <br /><br />
                    </p>
                    <Link to='/login'><Button variant="outline-primary">Login &gt;&gt;</Button></Link>
                     &nbsp;&nbsp;
                    <Link to='/register'><Button variant="outline-primary">Register &gt;&gt;</Button></Link>
                </>
            )
            :
            (<></>)
        }
      </div>
    );
    */
}

export default RequireLoginPage;