import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SiteNav(props) {
    console.log(props);
    const navigate = useNavigate();
    
    const handleLogout = async () => {
        try {
            console.log('Logout');
            await Auth.signOut();

            props.updateAuthStatus(false);
            navigate('/');
        } catch (err) { console.log(err); }
    };

    console.log(props);

    return (
        <header className="bg-green text-white fs-4 fw-bold">
            <Navbar expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand>
                        <Nav.Link href="/">
                            <img 
                                className="logo" 
                                src="/img/logo.png" 
                                alt="AITutorPanda" 
                                style={{ width: '200px', height: 'auto' }} 
                            />
                        </Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/grammar">Grammar</Nav.Link> {/* New Grammar menu item */}
                        </Nav>
                        {
                            props.isAuthenticated !== false && (
                                <Nav className="ms-md-auto">
                                    <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                                </Nav>
                            )
                        }
                        {
                            props.isAuthenticated === false && (
                                <Nav className="ms-md-auto">
                                    <Nav.Link href="/login">Login</Nav.Link>
                                    <Nav.Link href="/register">Register</Nav.Link>
                                </Nav>
                            )
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default SiteNav;
