import React from 'react';
import { Card } from 'react-bootstrap';
import MultiLevelAccordion from '../../atp_modules/MultiLevelAccordion';

const jsonData = [
    {
        "header": "Tense",
        "text": "Description for Level 1 Item 1",
        "items": [
            {
                "header": "Present Tense",
                "text": "Learn about Present Tense",
                "items": [
                    { "header": "Simple Present Tense", "text": "Learn about Simple Present Tense", "body": "" },
                    { "header": "Present Continuous Tense", "text": "Learn about Present Continuous Tense", "body": "" },
                    { "header": "Present Perfect Tense", "text": "Learn about Present Perfect Tense", "body": "" },
                    { "header": "Present Perfect Continuous Tense", "text": "Learn about Present Perfect Continuous Tense", "body": "" }
                ]
            },
            {
                "header": "Past Tense",
                "text": "Learn about Past Tense",
                "items": [
                    { "header": "Simple Past Tense", "text": "Learn about Simple Past Tense", "body": "" },
                    { "header": "Past Continuous Tense", "text": "Learn about Past Continuous Tense", "body": "" },
                    { "header": "Past Perfect Tense", "text": "Learn about Past Perfect Tense", "body": "" },
                    { "header": "Past Perfect Continuous Tense", "text": "Learn about Past Perfect Continuous Tense", "body": "" }

                ]
            },
            {
                "header": "Future Tense",
                "text": "Learn about Future Tense",
                "items": [
                    { "header": "Simple Future Tense", "text": "Learn about Simple Future Tense", "body": "" },
                    { "header": "Future Continuous Tense", "text": "Learn about Future Continuous Tense", "body": "" },
                    { "header": "Future Perfect Tense", "text": "Learn about Future Perfect Tense", "body": "" },
                    { "header": "Future Perfect Continuous Tense", "text": "Learn about Future Perfect Continuous Tense", "body": "" }

                ]
            }
        ]
    },
    {
        "header": "Prepositions",
        "text": "Learn about Prepositions",
        "items": [
            { "header": "Simple Prepositions", "text": "Learn about Simple Prepositions", "body": "" },
            { "header": "Compound Prepositions", "text": "Learn about Compound Prepositions", "body": "" },
            { "header": "Phrase Prepositions", "text": "Learn about Phrase Prepositions", "body": "" }
        ]
    },
    {
        "header": "Voice",
        "text": "Learn about Voice in English Grammar",
        "items": [
            { "header": "Active Voice", "text": "Learn about Active Voice", "body": "" },
            { "header": "Passive Voice", "text": "Learn about Passive Voice", "body": "" }
        ]
    }
];

const ExploreGrammar = () => {
    return (
        <Card>
            <Card.Body>
                <div className="bg-light-green text-white p-3 mb-3 rounded">
                    <h5>Explore English Grammar</h5>
                    <p>Learn and practice various aspects of English Grammar with our interactive content.</p>
                </div>
                <MultiLevelAccordion data={jsonData} />
            </Card.Body>
        </Card>
    );
};

export default ExploreGrammar;
