import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';

import SiteNav from './components/common/SiteNav';
import SiteFooter from './components/common/SiteFooter';
import HomePage from './components/home/HomePage';
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import ValidatePage from './components/auth/ValidatePage';
import Subjects from './components/subjects/Subjects';
import English from './components/subjects/english/English';
import Tenses from './components/subjects/english/Tenses';
import QAwithAPI from './components/subjects/english/QAwithAPI';
import LearnTopicPage from './components/subjects/LearnTopicPage';
import ExploreGrammar from './components/subjects/ExploreGrammar';

import { Auth } from 'aws-amplify';
Amplify.configure(awsExports);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  function updateAuthStatus(authStatus) {
    setIsAuthenticated(authStatus)
  }

/*
  useEffect(()=>{
    const authStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        console.log(`Authentication Status is set to true`);
        setIsAuthenticated('true');
      }  
    }
    catch (err)
    {
    }
  }
 },[])
*/

 useEffect( () =>{
  (async () => {
    try {
      const user =  await Auth.currentAuthenticatedUser();
      console.log(`Authentication Status is set to true`);
      setIsAuthenticated(true);
    }
    catch(err)
    {
      console.log('error', err);
      console.log(`Authentication Status is set to false`);
      setIsAuthenticated(false);
    }
  })();
  return () => {
    // this now gets called when the component unmounts
  };

},[])

  return (
    <div>
      {<SiteNav isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus} /> }
      <Routes>
        <Route path='*' element={<HomePage isAuthenticated={isAuthenticated} />} />
        <Route path='/' exact={true} element={<HomePage isAuthenticated={isAuthenticated} />} />
        <Route path='/login' element={<LoginPage updateAuthStatus={updateAuthStatus} />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/validate' element={<ValidatePage />} />
        <Route path='/subjects' element={<Subjects isAuthenticated={isAuthenticated} />} />
        <Route path='/subjects/english' element={<English isAuthenticated={isAuthenticated} />} />
        <Route path='/subjects/english/tenses' element={<QAwithAPI isAuthenticated={isAuthenticated} />} />
        <Route path='/subjects/english/qawithapi' element={<QAwithAPI isAuthenticated={isAuthenticated} />} />
        <Route path="/learntopic/:topic" element={<LearnTopicPage isAuthenticated={isAuthenticated} />} />
        <Route path="/grammar" element={<ExploreGrammar isAuthenticated={isAuthenticated} />} />
      </Routes>
      <SiteFooter />
    </div>
  )
};

export default App;