import Container from 'react-bootstrap/Container';

function SiteFooter() {
    return (

        <footer className="bg-green text-white text-center py-3 mt-4">
            <Container>
            <p>&copy; 2024 AITutorPanda. All Rights Reserved.</p>
            </Container>
        </footer>
    )
}

export default SiteFooter;