import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import RequireLoginPage from '../../auth/RequireLoginPage';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ThreeDots } from 'react-loading-icons'; // Import ThreeDots loader

const QAwithAPI = (props) => {
  const [answers, setAnswers] = useState({});
  const [questionsWithExplanations, setQuestionsWithExplanations] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true); // New state for loading indicator

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://zkkldo18wa.execute-api.us-east-1.amazonaws.com/dev/getcontent/tenses');
        console.log("response is", response);
        if (response.status !== 200) {
          throw new Error('Failed to fetch questions');
        }
        console.log("after response.status response.data.data is", response.data.data);

        const { questions } = response.data.data;
        console.log("questions is", questions);

        const initialAnswers = questions.reduce((acc, question) => {
          acc[question.id] = '';
          return acc;
        }, {});

        setQuestionsWithExplanations(questions);
        setAnswers(initialAnswers);
        setLoading(false); // Mark loading as false after fetching data
      } catch (error) {
        console.error('Error fetching questions:', error);
        // Handle error (e.g., show error message)
      }
    };

    fetchQuestions();
  }, []);

  const handleAnswerChange = (e, questionId) => {
    const { value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSuggestedVerbClick = (questionId) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // Optionally, you can update state or perform further actions based on the corrected answers
  };

  const handleTryAgain = () => {
    const initialAnswers = questionsWithExplanations.reduce((acc, question) => {
      acc[question.id] = '';
      return acc;
    }, {});
    setAnswers(initialAnswers);
    setSubmitted(false);
  };

  const renderAnswerIndicator = (question) => {
    const userAnswer = answers[question.id] || '';
    const isCorrect = userAnswer.toLowerCase() === question.correctAnswer.toLowerCase();

    if (submitted) {
      return (
        <div style={{ marginTop: '10px' }}>
          {isCorrect ? (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon icon={faCheck} /> {question.sentence.replace('______', question.correctAnswer)}
              <br />
              <hr />
            </div>
          ) : (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon icon={faTimes} /> {question.sentence.replace('______', userAnswer)}
              <br />
              <div style={{ color: 'black' }}>
                <small>
                  <strong>{`Correct Answer ${question.number}: "${question.sentence.replace('______', question.correctAnswer)}"`}</strong>
                  <br />
                  {question.detailExplanation}
                  <br />
                  {`Type of Tense: ${question.tenseType}`}
                  <br />
                  {`Subject-Verb Agreement: The verb "${question.correctAnswer}" matches the subject in number (${question.tenseType === 'Simple Present' ? 'She/He/It' : 'They'})`}
                  <br />
                  <hr />
                </small>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <Container>
      <Row>
        <RequireLoginPage isAuthenticated={props.isAuthenticated} />
      </Row>
      {props.isAuthenticated && (
        <Row>
          <Col>
            {loading ? (
              <div className="spinner-container">
                <ThreeDots stroke="#7983ff" />
              </div>
            ) : (
              <>
                <h2>Fill in the Blank Questions</h2>
                <form onSubmit={handleSubmit}>
                  {questionsWithExplanations.map((question) => (
                    <div key={question.id} className="question" style={{ marginBottom: '20px' }}>
                      <p><strong>Question {question.number}:</strong> {question.sentence.includes('______') ? (
                        <>
                          {question.sentence.split('______').map((part, index) => (
                            <React.Fragment key={index}>
                              {part}
                              {index === 0 && (
                                <input
                                  type="text"
                                  value={answers[question.id] || ''}
                                  onChange={(e) => handleAnswerChange(e, question.id)}
                                  placeholder={question.suggestedVerb}
                                  onClick={() => handleSuggestedVerbClick(question.id)}
                                  style={{ width: '80px', textAlign: 'center', border: 'none', borderBottom: '1px solid black' }}
                                  required
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        question.sentence
                      )}
                      </p>
                      {renderAnswerIndicator(question)}
                    </div>
                  ))}

                  <div style={{ marginTop: '30px' }}>
                    {!submitted && (
                      <Button type="submit">Submit Answers</Button>
                    )}
                    {submitted && (
                      <Button onClick={handleTryAgain}>Try Again</Button>
                    )}
                  </div>
                </form>
              </>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default QAwithAPI;
