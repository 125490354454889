import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LearnTopicPage from '../components/subjects/LearnTopicPage'; // Import the LearnTopicPage component


const MultiLevelAccordion = ({ data }) => {
    const renderAccordionItems = (items, level = 1) => {
        return items.map((item, index) => (
            <Accordion.Item key={`${level}-${index}`} eventKey={`${level}-${index}`}>
                <Accordion.Header>{item.header}</Accordion.Header>
                <Accordion.Body style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
                    <p>
                        Learn more about&nbsp;
                        <Link to={`/learntopic/${item.header}`}>
                            {item.header}
                        </Link>
                    </p>
                    {item.items ? (
                        <Accordion>
                            {renderAccordionItems(item.items, level + 1)}
                        </Accordion>
                    ) : (
                        <p>{item.body}</p>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        ));
    };

    const splitDataIntoColumns = (data, numColumns) => {
        const columns = Array.from({ length: numColumns }, () => []);
        data.forEach((item, index) => {
            const columnIndex = index % numColumns;
            columns[columnIndex].push(item);
        });
        return columns;
    };

    const columns = splitDataIntoColumns(data, 2); // Split data into 2 columns

    return (
        <Container>
            <Row>
                {columns.map((column, colIndex) => (
                    <Col key={colIndex}>
                        <Accordion>
                            {renderAccordionItems(column)}
                        </Accordion>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default MultiLevelAccordion;
