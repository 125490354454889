import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import RequireLoginPage from '../auth/RequireLoginPage';
import ExploreGrammar from '../subjects/ExploreGrammar';

function HomePage(props) {
    const [showAccordion, setShowAccordion] = useState(false);

    const handleGetStartedClick = () => {
        setShowAccordion(true);
    };

    return (
        <Container>
            <Container className="my-2">
                <Row>
                    <Col>
                        <Card className="border-0">
                            <Card.Body>
                                <Card.Title><h3>Welcome to AITutorPanda!</h3></Card.Title>
                                <Card.Text className="fw-bold">
                                    <br />
                                    <h4>
                                        Enhance your skills in the English language. Use our AI-powered skills assessments and specialized trainings to improve your proficiency.
                                    </h4>
                                </Card.Text>
                                <RequireLoginPage isAuthenticated={props.isAuthenticated} />
                                {!showAccordion && props.isAuthenticated !== false && (
                                    <Button variant="outline-primary" onClick={handleGetStartedClick}>
                                        Get Started! >>
                                    </Button>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {showAccordion && (
                    <Row className="mt-3">
                        <Col>
                            <ExploreGrammar />
                        </Col>
                    </Row>
                )}
            </Container>
        </Container>
    );
}

export default HomePage;
