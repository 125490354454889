import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import RequireLoginPage from '../../auth/RequireLoginPage';

function English(props) {

    return (
        <Container>
            <Row className="px-1 my-2">
                <Col><h1>I want to learn about</h1></Col>
            </Row>
            <Row>
            {
                <RequireLoginPage isAuthenticated={props.isAuthenticated}/>
            }
            </Row>
            <Row>
            {
                    props.isAuthenticated === true && (
                        <>
                            <Col className="px-2 my-2">
                                <Card style={{ width: '12rem' }}>
                                <Button size="lg" href="/subjects/english/qawithapi">Any Topic Q&A</Button>
                                </Card>
                            </Col>
                            <Col className="px-2 my-2">
                                <Card style={{ width: '12rem' }}>
                                <Button size="lg" href="/subjects/english/tenses">Tenses</Button>
                                </Card>
                            </Col>
                            <Col className="px-2 my-2">
                                <Card style={{ width: '12rem' }}>
                                <Button size="lg" href="/subjects/english/verbs">Verbs</Button>
                                </Card>
                            </Col>
                        </>

                    )
            }
            </Row>
        </Container>
    )
}

export default English;